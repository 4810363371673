import moment from "moment";
import { AarsPlanlaegningStatusEnums } from "@/models/aarsPlanlaegningStatusEnums";
import IMwColumn from "@/components/mwUtils/mwTable/MwColumnModel";

export function formatDate(value: string | moment.Moment) {
  if (value && value instanceof moment) {
    return (value as moment.Moment).format("DD-MM-YYYY");
  }

  return value as string;
}

export function formatDateTime(value: string | moment.Moment) {
  if (value instanceof moment) {
    return (value as moment.Moment).format("DD-MM-YYYY HH:mm:ss");
  }
  return value as string;
}

export function formatBoolean(value: boolean | null | undefined) {
  if (value === true) {
    return "Ja";
  } else if (value === false) {
    return "Nej";
  } else if (value === null) {
    return "Ikke relevant";
  }
  return "";
}

export function formatBooleanGaeldendeTilladelse(value: boolean | null | undefined) {
  if (value === true) {
    return "Gældende";
  } else if (value === false) {
    return "Udløbet";
  }
  return "";
}

export function formatBooleanJaNej(value: boolean) {
  if (value) {
    return "Ja";
  } else {
    return "Nej";
  }
}

export function formatBooleanJaNejInverse(value: boolean) {
  if (value) {
    return "Nej";
  } else {
    return "Ja";
  }
}

export function formatBooleanGodkendtForkastet(value: boolean | null | undefined) {
  if (value) {
    return "Godkendt";
  } else if (value === false) {
    return "Forkastet";
  }

  return "-";
}

export function formatBooleanCheckmark(value: any) {
  return value ? "✓" : "";
}

export function formatAarsPlanlaegningStatus(value: any) {
  switch (value.toString()) {
    case AarsPlanlaegningStatusEnums.FREMTIDIG:
      return "Fremtidig";
    case AarsPlanlaegningStatusEnums.GAELDENDE:
      return "Gældende";
    case AarsPlanlaegningStatusEnums.HISTORISK:
      return "Historisk";
    default:
      return "";
  }
}

export function formatBooleanNoNullText(value: boolean | null | undefined) {
  if (value === true) {
    return "Ja";
  } else if (value === false) {
    return "Nej";
  } else if (value === null) {
    return "";
  }
  return "";
}
export function formatBooleanImporteretEksporteret(value: boolean | null | undefined) {
  if (value === true) {
    return "Importeret fra";
  } else if (value === false) {
    return "Eksporteret til";
  } else if (value === null) {
    return "";
  }
  return "";
}

export function formatLockedRowIcon(value: any) {
  if (!value) {
    return "";
  }

  return '<i class="fa fa-lock" title="Rækken er låst"></i>';
}

export function formatTilsynUdfoertIcon(data: any, columnOptions: IMwColumn) {
  const value = data[columnOptions.id];
  if (!value) {
    return "";
  }

  return '<i class="fas fa-check" title="Tilsynet er udført"></i>';
}

export function formatEmneKode(columnValue: any) {
  if (!columnValue) {
    console.error(`Cannot find property with name: ${columnValue} while formatting emne kode!`);
    return "FORMATTING ERROR";
  }

  const value: string = columnValue;

  if (!value || value.length < 1) {
    console.error(
      `Cannot format property with name: ${columnValue} for emne kode. The corresponding value is undefined, null or an empty string!`
    );
    return "FORMATTING ERROR";
  }

  return value
    .split(".")
    .map(item => item[0].toUpperCase() + item.substring(1))
    .join(" > ");
}

export function formatJordforureningsattest(columnValue: any) {
  if (columnValue.ejerlavKode && columnValue.matrikelNr) {
    return `<a href="https://jordrapport.miljoeportal.dk/?elav=${columnValue.ejerlavKode}&matrnr=${columnValue.matrikelNr}" target="_blank" title="Generér jordforureningsattest hos miljøportalen">Hent jordforureningsattest</i></a>`;
  }
  return '<span title="Ejerlavskode og/eller matrikelnr. mangler">-</span>';
}

const faktureringsType = [
  {
    id: "-",
    beskrivelse: "",
    kode: "",
    navn: ""
  },
  {
    id: "CPRNummer",
    beskrivelse: "",
    kode: "",
    navn: "CPR-nummer"
  },
  {
    id: "CVRNummer",
    beskrivelse: "",
    kode: "",
    navn: "CVR-nummer"
  }
];

export function formatLookup(data: any, columnOptions: IMwColumn) {
  let value: string = "";
  if (columnOptions.id == "kontaktpersonBetalerTypeKode") {
    if (data.kontaktpersonBetalerTypeKode != "") {
      for (const i in faktureringsType) {
        if (data.kontaktpersonBetalerTypeKode == faktureringsType[i].id) {
          value = faktureringsType[i].navn;
        }
      }
    }
  }

  return value;
}

export function formatIndberetningStatus(value: any) {
  switch (value.toLowerCase()) {
    case "indberetningfejlet":
      return "Indberetning fejlet";
    case "indberetningudfoert":
      return "Indberetning udført";
    case "sletningudfoert":
      return "Sletning udført";
    case "sletningfejlet":
      return "Sletning fejlet";
    case "inaktivvirksomhed":
      return "Inaktiv virksomhed";
    case "ikkeindberettetfejl":
      return "Ikke indberettet fejl";
  }
  return value;
}

export function formatOverskridelseIcon(value: boolean) {
  if (value) {
    return "";
  } else {
    return '<i class="fa fa-warning" title="Max grænse overskreddet"></i>';
  }
}

export function formatPejlingSituation(value: string) {
  switch (value) {
    case "0":
      return "I ro";
    case "1":
      return "I drift";
    default:
      return "Ingen situation angivet";
  }
}
// Formats the column Navn in DMAVirksomhedgruppe to return "Ingen", 1a, 1b or 2
export function formatKategori(value: string | null) {
  if (value == null || value == "") return "";
  if (value.startsWith("3")) return "Ingen";
  const split = value.split("_");
  return split[0];
}
