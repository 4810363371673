<template>
  <div class="navbar navbar-expand-md navbar-north w-100" role="navigation">
    <div class="container-fluid" :class="{ fullscreen: sessionStore.isFullscreen }">
      <div class="row">
        <div class="col-2">
          <router-link :to="{ name: 'home' }">
            <img
              :src="mwLogoHeader"
              height="32"
              width="138"
              class="logo"
              :class="{ 'puff-in-center': $router.currentRoute.value.name === 'login' }"
              alt="MiljøWeb logo"
            />
          </router-link>
          <img
            v-if="hostname != '' && hostname != 'miljoeweb.dk' && hostname != 'www.miljoeweb.dk'"
            :src="getLocationLabel()"
            height="48"
            width="96"
            class="siteLocation"
            :alt="getEnvironmentLabel()"
            :class="{ 'puff-in-center': $router.currentRoute.value.name === 'login' }"
          />
        </div>

        <div class="col-5">
          <!-- QQQ <SystemMessage v-if="$route.name && !$route.name.includes('login')"></SystemMessage>-->
        </div>

        <div class="col-5 navbar-nav">
          <div class="collapse navbar-collapse">
            <ul class="nav" v-if="isLoggedIn">
              <MwFavorites />
              <MwKundevaelger />
              <li class="nav-item dropdown">
                <a class="nav-link nav-start dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <MwIcon icon="user" />{{ sessionStore.bruger.userName }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <div style="max-height: 400px; overflow: auto">
                    <a @click="showUserSettingsModal" style="color: #3a3a3a; text-transform: initial" class="dropdown-item">
                      <MwIcon icon="cog" type="fas" class="mr-2" />Indstillinger
                    </a>
                    <a
                      href="http://www.anpdm.com/form/41455E437948455A457840/444A5041704541504A71"
                      target="_blank"
                      style="color: #3a3a3a; text-transform: initial"
                      class="dropdown-item"
                    >
                      <MwIcon icon="envelope" type="fas" class="mr-2" />Nyhedsbrev
                    </a>
                    <a
                      href="https://miljoeweb-help.cbmanager.dk?sitepassword=mw"
                      target="_blank"
                      style="color: #3a3a3a; text-transform: initial"
                      class="dropdown-item"
                    >
                      <MwIcon icon="info-circle" type="fas" class="mr-2" />Support
                    </a>
                    <router-link
                      v-if="sessionStore.hasRights(KundeRoleEnums.SYSTEM_ADMIN)"
                      :to="{ name: 'kundemoduler' }"
                      style="color: #3a3a3a; text-transform: initial"
                      class="dropdown-item"
                      ><MwIcon icon="toolbox" type="fas" class="mr-2" />
                      Systemadministration
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <button type="button" class="dropdown-item" @click="sessionStore.logoutUser">
                      <MwIcon icon="sign-out" class="mr-2" />Log ud
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSessionStore } from "@/stores/session";
import { computed } from "vue";
import MwFavorites from "@/components/mwUtils/MwFavorites.vue";
import MwKundevaelger from "@/components/mwUtils/MwKundevaelger.vue";
import MwIcon from "@/components/mwUtils/MwIcon.vue";

import devLabel from "@/assets/images/devLabel.png";
import testLabel from "@/assets/images/testLabel.png";
import preprodLabel from "@/assets/images/preprodLabel.png";
import mwLogoHeader from "@/assets/images/mwLogoHeader.svg";
import { useMainStore } from "@/stores/main";
import { useRouter } from "vue-router";
import { KundeRoleEnums } from "@/models/KundeRoleEnums";

const sessionStore = useSessionStore();
const hostname = window.location.hostname;

const router = useRouter();

function getEnvironmentLabel() {
  switch (hostname) {
    case "localhost":
    case "miljoeweb.gdev.local":
      return "Development";
    case "test.miljoeweb.dk":
      return "Test";
    case "preprod.miljoeweb.dk":
      return "Preprod";
  }

  return "";
}

function getLocationLabel() {
  let imageUrl = "";

  switch (hostname) {
    case "localhost":
    case "miljoeweb.gdev.local":
      //imageUrl = '../../src/assets/images/devLabel.png';
      imageUrl = devLabel;
      break;
    case "test.miljoeweb.dk":
      //imageUrl = '../../src/assets/images/testLabel.png';
      imageUrl = testLabel;
      break;
    case "preprod.miljoeweb.dk":
      //imageUrl = '../../src/assets/images/preprodLabel.png';
      imageUrl = preprodLabel;
      break;
  }

  if (imageUrl) {
    return new URL(imageUrl, import.meta.url).href;
  }

  return "";
}

const isLoggedIn = computed(() => sessionStore.bruger.brugerId);

function showUserSettingsModal() {
  const mainStore = useMainStore();
  mainStore.userSettingsModalVisible = true;
}
</script>

<style scoped>
.container-fluid {
  margin-top: 0;
  height: 60px;
}
.nav-item {
  margin-left: 4em !important;
}
.nav-text {
  position: relative;

  white-space: nowrap;
  padding: 5px 0 2px !important;
  min-height: 30px !important;
}
.navbar {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.dropdown-toggle::after {
  background-position: -1px 2px !important;
}
.logo {
  top: 3px;
  position: relative;
}
.siteLocation {
  top: 1px;
  left: 15px;
  position: relative;
}
@media screen and (max-width: 1000px) {
  .siteLocation {
    display: none;
  }
}

.puff-in-center {
  -webkit-animation: puff-in-center 1.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  animation: puff-in-center 1.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(3);
    transform: scale(3);
    /* TODO:  Chrome har på visse maskiner et problem med at blur får animationen til at lagge. Overvej om vi skal se på dette problem, ellers fjern denne hæslige kommentar*/
    -webkit-filter: blur(8px);
    filter: blur(8px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(3);
    transform: scale(3);
    -webkit-filter: blur(8px);
    filter: blur(8px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
</style>
<style>
/* Nedenstående css er for at vise menu item i tablet portræt mode- En overskrivelse af sweco design som breaker på et andet tidspunkt. 
Korrekt løsning ville være at vise en burgermenu */
@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav .nav-item,
  .navbar .navbar-nav .nav > li {
    margin-left: 4em !important;
  }
}
@media (min-width: 768px) {
  .navbar .navbar-nav .navbar-collapse {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav {
    margin-bottom: auto;
    -webkit-box-orient: initial !important;
    -webkit-box-direction: initial !important;
    -ms-flex-direction: initial !important;
    flex-direction: initial !important;
  }
}
.navbar a.dropdown-item {
  padding-left: 0.95rem !important;
}
@media (min-width: 768px) {
  .navbar .nav .nav-item .dropdown-menu,
  .navbar .nav .nav-item .twitter-typeahead .tt-menu,
  .navbar .nav > li .dropdown-menu,
  .navbar .nav > li .twitter-typeahead .tt-menu,
  .twitter-typeahead .navbar .nav .nav-item .tt-menu,
  .twitter-typeahead .navbar .nav > li .tt-menu,
  .navbar .dropdown-menu.show {
    background-color: white;
    -webkit-box-shadow: 0 0 0.95rem rgb(0 0 0 / 30%);
    box-shadow: 0 0 0.95rem rgb(0 0 0 / 30%);
    margin: 0.95rem 0 0;
    padding-left: 0rem;
    outline: 0;
    color: #3a3a3a;
  }

  .navbar button.dropdown-item {
    padding: 0.3166666667rem 0.95rem;
    white-space: nowrap;
    outline: 0;
    color: #3a3a3a;
    padding-left: 0.95rem !important;
  }
  .navbar button.dropdown-item.dark-gray-superlight {
    padding: 0.3166666667rem 0.95rem;
    background-color: #d0d3d6;
    color: #3a3a3a;
  }
  .navbar .dropdown-item:focus,
  .navbar .dropdown-item:hover,
  .navbar .twitter-typeahead .tt-menu div.tt-suggestion:focus,
  .navbar .twitter-typeahead .tt-menu div.tt-suggestion:hover {
    background-color: #eee !important;
    outline: 0;
  }
  .navbar .nav .nav-item .dropdown-menu .dropdown-item,
  .navbar .nav .nav-item .twitter-typeahead .tt-menu .dropdown-item,
  .navbar .nav .nav-item .twitter-typeahead .tt-menu div.tt-suggestion,
  .navbar .nav > li .dropdown-menu .dropdown-item,
  .navbar .nav > li .twitter-typeahead .tt-menu .dropdown-item,
  .navbar .nav > li .twitter-typeahead .tt-menu div.tt-suggestion,
  .twitter-typeahead .navbar .nav .nav-item .tt-menu .dropdown-item,
  .twitter-typeahead .navbar .nav .nav-item .tt-menu div.tt-suggestion,
  .twitter-typeahead .navbar .nav > li .tt-menu .dropdown-item,
  .twitter-typeahead .navbar .nav > li .tt-menu div.tt-suggestion,
  .twitter-typeahead .tt-menu .navbar .nav .nav-item .dropdown-menu div.tt-suggestion,
  .twitter-typeahead .tt-menu .navbar .nav > li .dropdown-menu div.tt-suggestion {
    color: #3a3a3a;
  }
}
</style>
