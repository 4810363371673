import { defineStore } from "pinia";
import { VirksomhedsState } from "@/stores/statetypes";
import Virksomhed, { NewVirksomhed } from "@/models/virksomhed/virksomhed";
import { HttpResponse, QueryOptions } from "@/utils/networking/interfaces";
import { fetchGet, fetchPost, fetchPut } from "@/utils/networking/httpclient";
import UrlBuilder from "@/utils/networking/urlBuilder";
import { Basis } from "@/models/virksomhed/basis";

export const useVirksomhedStore = defineStore("virksomhed", {
  state: (): VirksomhedsState => {
    return {
      currentVirksomhed: new Virksomhed(),
      branches: [],
      newVirksomheder: []
    };
  },
  actions: {
    async fetchVirksomheder(options: QueryOptions) {
      const urlBuilder = new UrlBuilder("virksomhed/list");

      urlBuilder.addParameter("kundeId", options.kundeId);
      urlBuilder.addParameter("start", options.start);
      urlBuilder.addParameter("take", options.take);
      urlBuilder.addParameter("sortByColumn", options.sortByColumn);
      urlBuilder.addParameter("sortDirection", options.sortDirection);
      urlBuilder.addParameter("filter", options.filter);
      urlBuilder.addParameter("virksomhedsType", options.virksomhedsType);
      urlBuilder.addParameterWithArrayValue("filteredColumns", options.filteredColumns);

      return await fetchGet(urlBuilder.generateUrlWithParams());
    },
    async fetchVirksomhed(virksomhedId: string): Promise<HttpResponse> {
      const response = await fetchGet("virksomhed/" + virksomhedId);

      const virksomhed: Virksomhed = Object.assign(new Virksomhed(), response.data);

      this.currentVirksomhed = virksomhed;

      return response;
    },

    //UPDATE BASIS
    async updateVirksomhedBasis(virksomhed: Virksomhed): Promise<HttpResponse> {
      const response = await fetchPut("virksomhed/" + virksomhed.id + "/basis", virksomhed.basis);
      this.currentVirksomhed.basis = Object.assign(new Basis(), response.data.basis);

      return response;
    },

    //UPDATE FRA CVR
    async updateVirksomhedCVR(virksomhedId: string): Promise<HttpResponse> {
      const url: string = `virksomhed/OpdaterCVROplysningerForVirksomhed?virksomhedid=${virksomhedId}`;
      const payload: any = null;

      return fetchPost(url, payload);
    },

    async fetchVirksomhederFraCVR(lookupVirksomhedCVRQuery: any) {
      let queryBrancheKoder = "";

      for (const brancheCode of lookupVirksomhedCVRQuery.brancheKoder) {
        queryBrancheKoder += "&brancheKoder=" + brancheCode;
      }

      const { status: status, data } = await fetchGet(
        "Virksomhed/LookupVirksomhederFraCvr?kommuneId=" +
          lookupVirksomhedCVRQuery.kommuneId +
          "&firstStart=" +
          lookupVirksomhedCVRQuery.firstStart +
          "&lastStart=" +
          lookupVirksomhedCVRQuery.lastStart +
          "&soegeTekst=" +
          lookupVirksomhedCVRQuery.soegeTekst +
          "&tilladPNrUdenforKommune=" +
          lookupVirksomhedCVRQuery.tilladPNrUdenforKommune +
          "&tilladPNrDublering=" +
          lookupVirksomhedCVRQuery.tilladPNrDublering +
          "&virksomhedsType=" +
          lookupVirksomhedCVRQuery.type +
          queryBrancheKoder
      );

      this.newVirksomheder = [...data];
    },
    async createVirksomhedFraCVR(createVirksomhed: any): Promise<HttpResponse> {
      let queryPNRListe = "";

      for (const pNr of createVirksomhed.PNRListe) {
        queryPNRListe += "&PNRListe=" + pNr;
      }

      return await fetchPost(
        "Virksomhed/OpretVirksomhedFraCvrData?opretterId=" +
          createVirksomhed.opretterId +
          "&kommuneId=" +
          createVirksomhed.kommuneId +
          "&virksomhedsType=" +
          createVirksomhed.virksomhedsType +
          queryPNRListe,
        createVirksomhed
      );
    },

    async createVirksomhedManuel(createVirksomhed: any): Promise<HttpResponse> {
      return await fetchPost(
        "Virksomhed/OpretVirksomhedFraDawaData?Navn=" +
          createVirksomhed.Navn +
          "&dawaAdresseId=" +
          createVirksomhed.dawaAdgangsadresseId +
          "&dawaAdresseType=" +
          createVirksomhed.dawaAdressetype +
          "&etableringsDato=" +
          createVirksomhed.etableringsDato +
          "&opretterId=" +
          createVirksomhed.opretterId +
          "&kommuneId=" +
          createVirksomhed.kommuneId +
          "&virksomhedsType=" +
          createVirksomhed.virksomhedsType,

        createVirksomhed
      );
    },
    async fetchBrancher() {
      const { status: status, data } = await fetchGet("branche/list");
      this.branches = [...data];
    }
  },
  getters: {
    VIRKSOMHEDER_FRA_CVR(): NewVirksomhed[] {
      return this.newVirksomheder;
    }
  }
});
