import Favorite from "@/models/favorite";
import { MwTableUserSettings } from "@/models/mwTableUserSettings";
import moment from "moment";

export default class Bruger {
  userName: string = "";
  brugerId: string = "";
  roller: string[] = [];
  moduler: string[] = [];
}

export class Kunde {
  kundeId: string = "";
  kundeNavn: string = "";
  kundeNr: string = "";
}

export class KundeSettings {
  favorites: Favorite[] = [];
}

export class BrugerSettings {
  hideTimeline: boolean = false;
  hideLogs: boolean = false;
  hideAdHocOpgaver: boolean = false;
  mwTablesUserSettings: MwTableUserSettings[] = [];
  activeKundeId: string = "";
  hidePlanlaegningGraphs: boolean = true;
  hidePlanlaegningUserInfo: boolean = false;
  oversigtOverIndberetningerAar: number = moment().year();
  visFavoritAdresser: boolean = false;
}

export class BrugerHosKundeModel {
  brugerId: string;
  navn: string;
  email: string;
  tlf: string;
  roller: RolleModel[];
}

export class RolleModel {
  id: string;
  navn: string;
}
