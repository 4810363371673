import { defineStore } from "pinia";
import Bruger, { BrugerSettings, Kunde, KundeSettings } from "@/models/bruger";
import Frase from "@/models/frase";
import Favorite from "@/models/favorite";
import Emne from "@/models/emne";
import { MwTableUserSettings } from "@/models/mwTableUserSettings";
import TilsynKonklusion from "@/models/virksomhed/tilsynkonklusion";
import { fetchGet, fetchPost, fetchPut } from "@/utils/networking/httpclient";
import FilterSettings from "@/models/filterSettings";
import { HttpResponse } from "@/utils/networking/interfaces";
import { VirksomhedsTypeEnum, VirksomhedsTypeTextEnum } from "@/models/virksomhed/virksomhedsTypeEnum";
import { LogItem } from "@/models/virksomhed/logItem";
import { useVirksomhedStore } from "@/stores/virksomhed";
import { useVandanlaegStore } from "@/stores/vandanlaeg";
import { AdHocOpgaveItem } from "@/models/virksomhed/adHocOpgaveItem";
import { useLookupStore } from "@/stores/lookup";
import { useVandanlaegLookupStore } from "@/stores/vandanlaeg-lookup";
import { useAdminStore } from "@/stores/admin";
import { usePlanlaegningStore } from "@/stores/planlaegning";
import { useFakturaStore } from "@/stores/faktura";
import { KundeRoleEnums } from "@/models/kundeRoleEnums";
import * as jwtService from "@/utils/jwtReader";

export const useSessionStore = defineStore("session", {
  state: () => {
    return {
      bruger: new Bruger() as Bruger,
      activeKunde: new Kunde() as Kunde,
      brugerSettings: new BrugerSettings() as BrugerSettings,
      kundeSettings: new KundeSettings() as KundeSettings,
      fraser: new Map<string, Frase[]>(),
      emner: [] as Emne[],
      tilsynKonklusioner: [] as TilsynKonklusion[],
      fullscreen: false,
      activePlanlaegningsAar: 0,
      activeFilters: [] as FilterSettings[]
    };
  },
  getters: {
    kundeId(): string {
      return this.activeKunde.kundeId;
    },
    favorites(): Favorite[] {
      return this.kundeSettings.favorites;
    },
    isFullscreen(): boolean {
      return this.fullscreen;
    },
    isTimelineHidden(): boolean {
      return this.brugerSettings.hideTimeline;
    },
    isLogsHidden(): boolean {
      return this.brugerSettings.hideLogs;
    },
    isAdHocOpgaverHidden(): boolean {
      return this.brugerSettings.hideAdHocOpgaver;
    },
    isPlanlaegningGraphsHidden(): boolean {
      return this.brugerSettings.hidePlanlaegningGraphs;
    },
    isPlanlaegningUserInfoHidden(): boolean {
      return this.brugerSettings.hidePlanlaegningUserInfo;
    }
  },
  actions: {
    logoutUser() {
      localStorage.removeItem("usertoken");

      const lookupStore = useLookupStore();
      const vandanlaegLookupStore = useVandanlaegLookupStore();
      const virksomhedStore = useVirksomhedStore();
      // const kampagneStore = useKampagneStore();
      const vandanlaegStore = useVandanlaegStore();
      const adminStore = useAdminStore();
      const planlaegningStore = usePlanlaegningStore();
      const fakturaStore = useFakturaStore();

      lookupStore.$reset();
      vandanlaegLookupStore.$reset();
      this.$reset();
      virksomhedStore.$reset();
      // kampagneStore.$reset();
      vandanlaegStore.$reset();
      adminStore.$reset();
      planlaegningStore.clearState();
      fakturaStore.$reset();

      if (import.meta.env.VITE_LOGIN_TYPE === "STS" && !localStorage.usertoken) {
        window.location.href = import.meta.env.VITE_STS_LOGOUT_URL;
      } else {
        //We can't use this.$router.push
        //We need to use window.location.replace to make sure minimap works if you login with a different user, and to reset lookups.
        //url can't be /#/swecologin ( url with anchor ) because then it wont force a browsernavigation
        window.location.replace("/");
      }
    },
    hasSomeRights(requiredResources: string[]): boolean {
      return requiredResources.some(resource => this.bruger.roller.includes(resource));
    },
    hasEveryRights(requiredResources: string[]): boolean {
      return requiredResources.every(resource => this.bruger.roller.includes(resource));
    },
    canEditVirksomhed(virksomhedstype: string | VirksomhedsTypeTextEnum): boolean {
      switch (virksomhedstype.toLocaleLowerCase()) {
        case VirksomhedsTypeTextEnum.LANDBRUG:
          return this.hasRights(KundeRoleEnums.SAGSBEHANDLER_LANDBRUG);
        case VirksomhedsTypeTextEnum.VANDANLAEG:
          return this.hasRights(KundeRoleEnums.SAGSBEHANDLER_VANDANLAEG);
        case VirksomhedsTypeTextEnum.VIRKSOMHED:
          return this.hasRights(KundeRoleEnums.SAGSBEHANDLER_VIRKSOMHED);
        default:
          return false;
      }
    },
    hasAccess(requiredModule: string): boolean {
      return this.bruger.moduler.includes(requiredModule);
    },
    hasRights(requiredResource: string): boolean {
      return this.bruger.roller.includes(requiredResource);
    },
    isFavorite(virksomhedId: string): boolean {
      return this.kundeSettings.favorites.some((favorite: any) => favorite.virksomhedId === virksomhedId);
    },
    updateUsertoken(usertoken: any) {
      localStorage.setItem("usertoken", usertoken);
    },
    async updateActivePrincipal(kundeId: string | undefined = undefined) {
      //Should only redirect on municipalityChange
      let shouldRedirectToHomepage = false;
      //If kundeId is specified, request a new token.
      //Otherwise work on token already in localstorage.
      if (kundeId) {
        const { data, status } = await fetchGet(`login/ChangeMunicipality?municipalityId=${kundeId}`);
        //If failed return without setting new token
        //which would be invalid
        if (status !== 200) {
          return;
        }

        shouldRedirectToHomepage = true;
        this.updateUsertoken(data);
      }

      const principal = await jwtService.decodeToken(localStorage.usertoken);

      //this is a workaround for the scenario where an old jwt structure is saved in the localstorage.
      //if it is not possible to get the principal bruger or municipality
      //logout the user, and the they will be thrown back to login page
      try {
        await this.fetchBrugerSettings();
        this.bruger = principal.bruger;
        this.activeKunde = principal.customer;
        this.brugerSettings.activeKundeId = this.activeKunde.kundeId;
        await this.updateBrugerSettings();

        await this.fetchKundeSettings();
      } catch {
        this.logoutUser();
        return;
      }

      if (shouldRedirectToHomepage) {
        //Send user to homepage, to avoid potentiel errors with access
        if (this.router.currentRoute.value.name == "home") {
          this.router.go(0);
        } else {
          this.router.push({ name: "home" });
        }
      }
    },
    updateEmner(items: Emne[]) {
      this.emner = items;
    },
    updateFraser({ kode, items }: any) {
      this.fraser.set(kode, items);
    },

    updateTilsynKonklusioner(tilsynKonklusioner: TilsynKonklusion[]) {
      // TODO: Move into dedicated store module.
      this.tilsynKonklusioner = tilsynKonklusioner;
    },
    updateActivePlanlaegningsAar(aar: number) {
      this.activePlanlaegningsAar = aar;
    },
    toggleVisFavoritAdresser(value: boolean) {
      this.brugerSettings.visFavoritAdresser = value;
    },
    async toggleTidslinje(value: boolean) {
      this.brugerSettings.hideLogs = true;
      this.brugerSettings.hideAdHocOpgaver = true;
      this.brugerSettings.hideTimeline = !this.brugerSettings.hideTimeline;

      await this.updateBrugerSettings();
    },

    async togglePlanlaegningGraphs(value: boolean) {
      this.brugerSettings.hidePlanlaegningUserInfo = true;
      this.brugerSettings.hidePlanlaegningGraphs = !this.brugerSettings.hidePlanlaegningGraphs;

      await this.updateBrugerSettings();
    },

    async togglePlanlaegningUserInfo(value: boolean) {
      this.brugerSettings.hidePlanlaegningGraphs = true;
      this.brugerSettings.hidePlanlaegningUserInfo = !this.brugerSettings.hidePlanlaegningUserInfo;

      await this.updateBrugerSettings();
    },

    toggleFullscreen(value?: boolean) {
      this.fullscreen = !this.fullscreen;
    },

    fetchMwTablesUserSettings() {
      const options = localStorage.getItem("mwTablesUserSettings");
      if (options && this.brugerSettings.mwTablesUserSettings.length === 0) {
        this.brugerSettings.mwTablesUserSettings = JSON.parse(options) as MwTableUserSettings[];
      }
    },

    applyKundeSettings(settings: KundeSettings) {
      this.kundeSettings = Object.assign(new KundeSettings(), settings);
      this.kundeSettings.favorites.sort((a: any, b: any) => {
        return a.displayName.toLowerCase() < b.displayName.toLowerCase()
          ? -1
          : a.displayName.toLowerCase() > b.displayName.toLowerCase()
          ? 1
          : 0;
      });
    },

    applyBrugerSettings(settings: BrugerSettings | any) {
      if (!settings) {
        return;
      }
      if (settings.activeKundeId === "" || settings.activeKundeId === undefined) {
        settings.activeKundeId = this.activeKunde.kundeId;
      }

      this.brugerSettings = Object.assign(new BrugerSettings(), settings);
    },
    async updateOversigtOverIndberetningAar(aar: number) {
      this.brugerSettings.oversigtOverIndberetningerAar = aar;
      await this.updateBrugerSettings();
    },

    // ACTIONS
    // ACTIONS
    // ACTIONS
    // ACTIONS

    async toggleFavorite(favorite: Favorite) {
      if (!favorite.virksomhedId) {
        throw new Error("Cannot toggle favorite state for virksomhed. No ID was provided!");
      }

      if (!favorite.virksomhedType) {
        throw new Error(
          "Cannot toggle favorite state for virksomhed with ID: " + favorite.virksomhedId + ". No virksomheds type was provided."
        );
      }

      this.addFavoriteToKundeSettings(favorite);

      await this.updateKundeSettings();
    },

    addFavoriteToKundeSettings(favorite: Favorite) {
      const index = this.kundeSettings.favorites.findIndex(f => f.virksomhedId === favorite.virksomhedId);
      if (index !== -1) {
        this.kundeSettings.favorites.splice(index, 1);
      } else {
        this.kundeSettings.favorites.push(favorite);
        this.kundeSettings.favorites.sort((a: any, b: any) => {
          return a.displayName.toLowerCase() < b.displayName.toLowerCase()
            ? -1
            : a.displayName.toLowerCase() > b.displayName.toLowerCase()
            ? 1
            : 0;
        });
      }
    },

    async resetFavorites() {
      this.kundeSettings.favorites = [];
      await this.updateKundeSettings();
    },

    async deleteFavorite(virksomhedId: string) {
      this.kundeSettings.favorites = this.kundeSettings.favorites.filter(x => x.virksomhedId !== virksomhedId);
      await this.updateKundeSettings();
    },

    async toggleLogs() {
      this.brugerSettings.hideTimeline = true;
      this.brugerSettings.hideAdHocOpgaver = true;
      this.brugerSettings.hideLogs = !this.brugerSettings.hideLogs;
      this.updateBrugerSettings();
    },

    async toggleAdHocOpgaver() {
      this.brugerSettings.hideTimeline = true;
      this.brugerSettings.hideLogs = true;
      this.brugerSettings.hideAdHocOpgaver = !this.brugerSettings.hideAdHocOpgaver;
      this.updateBrugerSettings();
    },

    async fetchKundeSettings() {
      const kundeId = this.activeKunde.kundeId;

      const { data } = await fetchGet("Bruger/HentKundeOpsaetning/" + kundeId);
      this.applyKundeSettings(JSON.parse(data.opsaetning));
    },

    async updateKundeSettings() {
      const kundeId = this.activeKunde.kundeId;

      await fetchPut("Bruger/GemKundeOpsaetning", {
        kundeId,
        opsaetning: JSON.stringify(this.kundeSettings)
      });
    },

    async fetchAdresse(virksomhedId: any) {
      return fetchGet("adresse/" + virksomhedId);
    },

    async fetchBrugerSettings() {
      const { data } = await fetchGet("Bruger/HentOpsaetning");
      const brugerSettings: BrugerSettings = JSON.parse(data.opsaetning);
      this.applyBrugerSettings(brugerSettings);
      return brugerSettings;
    },

    async resetBrugerSettings() {
      this.applyBrugerSettings({});

      await fetchPut("Bruger/GemOpsaetning", {
        opsaetning: JSON.stringify(this.brugerSettings)
      });
    },

    async updateBrugerSettings() {
      await fetchPut("Bruger/GemOpsaetning", {
        opsaetning: JSON.stringify(this.brugerSettings)
      });
    },

    async fetchSystemBeskeder() {
      const kundeId = this.activeKunde.kundeId;
      const response = await fetchGet("SystemBesked/HentSystemBeskeder/" + kundeId);
      this.updateSystemBeskeder(response.data);
    },

    /**
     * Fetches all phrases available for a specifif code.
     *
     * @param param0 Vuex action context
     * @param kode An unique code identifying the type and subtype of the fraser to fetch. An examle: tilsyn.konklusion
     */
    async fetchFraserFromEmneKode(kode: string): Promise<Frase[]> {
      // ****** CACHE DELEN ER UDKOMMENTERET, DA DEN BEVAREDE DE SAMME FRASER SELVOM MAN SKIFTEDE VIRKSOMHED  ******
      // ****** (DVS adresse/virksomhedsnavn fra virksomhed 1 blev vist i fraseteksten på virksomhed 2)       ******
      // const cached: Frase[] = getters.FRASER_FROM_EMNEKODE(kode);
      // if (cached && cached.length > 0) {
      //   return Promise.resolve(cached);
      // }

      //const emneKode = kode.substring(0, kode.indexOf('.'));

      const sessionStore = useSessionStore();

      try {
        const emne = (await this.fetchEmneFromKode(kode)) as Emne;

        const kundeId = this.activeKunde.kundeId;

        const response = await fetchGet(`frase/list/${kundeId}?emneId=${emne.id}`);
        if (response.status < 200 || response.status > 299 || response.data.error) {
          return Promise.reject(
            `Failed to fetch fraser for code: ${kode}. A non-success HTTP status code (${response.status}) or an error response was received!`
          );
        }

        const fraser: Frase[] = response.data;

        this.updateFraser({ kode, items: fraser });

        return Promise.resolve(fraser);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async fetchEmneFromKode(kode: string): Promise<Emne> {
      let emner: Emne[] = this.emner;
      if (emner.length < 1) {
        try {
          emner = await this.fetchEmner();
        } catch (error) {
          return Promise.reject(error);
        }
      }

      const emne: Emne | undefined = emner.find(emne => emne.kode.toUpperCase().startsWith(kode.toUpperCase()));
      if (emne == undefined) {
        return Promise.reject(`Couldn't fetch emne with a kode that starts with: ${kode}!`);
      }

      return Promise.resolve(emne);
    },

    async fetchEmner(): Promise<any> {
      try {
        const response = await fetchGet("emne/list");
        if (response.status < 200 || response.status > 299 || response.data.error) {
          return Promise.reject("Failed fetch emner. A non-success HTTP status code or an error response was received!");
        }

        this.updateEmner(response.data);
        return Promise.resolve(response);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async FETCH_TILSYN_KONKLUSIONER(kundeId: string): Promise<HttpResponse> {
      const response = await fetchGet("tilsyn/tilsynKonklusion/" + kundeId);

      this.tilsynKonklusioner = response.data;
      return response;
    },

    getMwTableUserSettings(tableName: string) {
      return this.brugerSettings.mwTablesUserSettings.find(mwTableUserSettings => mwTableUserSettings.tableName === tableName);
    },

    async updateMwTableUserSettings(newSettings: MwTableUserSettings) {
      const existingSettings = this.brugerSettings.mwTablesUserSettings.find(obj => obj.tableName == newSettings.tableName);
      if (!existingSettings) {
        this.brugerSettings.mwTablesUserSettings.push(newSettings);
      } else {
        const index = this.brugerSettings.mwTablesUserSettings.findIndex(i => i.tableName === newSettings.tableName);
        this.brugerSettings.mwTablesUserSettings[index] = newSettings;
      }

      await this.updateBrugerSettings();
    },
    async login(payload: any): Promise<any> {
      try {
        const { data, isError } = await fetchPost("login", payload);
        if (isError) {
          return { success: false, error: data };
        }
        this.updateUsertoken(data);
        return { success: true };
      } catch (error) {
        return { success: false, error: error };
      }
    },

    async loginSts(payload: any): Promise<{ success: boolean; error?: string }> {
      const { data, isError } = await fetchPost("login/sts", payload);
      if (!isError) {
        this.updateUsertoken(data);
        return { success: true, error: undefined };
      } else {
        return { success: false, error: data };
      }
    },

    async fetchLogs([virksomhedId, numberOfItems, virksomhedsType]: any): Promise<HttpResponse> {
      const url = "logbog/list/" + virksomhedId;
      //server understøtter ikke numberofitems endnu
      // if (numberOfItems !== undefined) {
      //   url += '?numberOfItems=' + numberOfItems;
      // }
      const response = await fetchGet(url);

      if (response.data) {
        if (virksomhedsType === VirksomhedsTypeEnum.VIRKSOMHED || virksomhedsType === VirksomhedsTypeEnum.LANDBRUG) {
          const virksomhedStore = useVirksomhedStore();
          if (virksomhedStore.currentVirksomhed) {
            virksomhedStore.currentVirksomhed.logs = response.data.map((v: any) => new LogItem(v));
          }
        } else if (virksomhedsType === VirksomhedsTypeEnum.VANDANLAEG) {
          const vandanlaegStore = useVandanlaegStore();
          if (vandanlaegStore.currentVandanlaeg) {
            vandanlaegStore.currentVandanlaeg.logs = response.data.map((v: any) => new LogItem(v));
          }
        }
      }

      return response;
    },

    async createLog(log: LogItem): Promise<HttpResponse> {
      return await fetchPost("logbog", log);
    },

    async fetchAdHocOpgaver([virksomhedId, numberOfItems, virksomhedsType]: any): Promise<HttpResponse> {
      const url = "AdHocOpgave/list/" + virksomhedId;
      const response = await fetchGet(url);

      if (response.data) {
        if (virksomhedsType === VirksomhedsTypeEnum.VIRKSOMHED || virksomhedsType === VirksomhedsTypeEnum.LANDBRUG) {
          const virksomhedStore = useVirksomhedStore();
          if (virksomhedStore.currentVirksomhed) {
            virksomhedStore.currentVirksomhed.adHocOpgaver = response.data;
          }
        } else if (virksomhedsType === VirksomhedsTypeEnum.VANDANLAEG) {
          const vandanlaegStore = useVandanlaegStore();
          if (vandanlaegStore.currentVandanlaeg) {
            vandanlaegStore.currentVandanlaeg.adHocOpgaver = response.data;
          }
        }
      }

      return response;
    },

    async createAdHocOpgave(adHocOpgave: AdHocOpgaveItem): Promise<HttpResponse> {
      return await fetchPost("AdHocOpgave", adHocOpgave);
    },

    async ping() {
      return fetchGet("test/ping");
    },

    async hentTilgaengeligeKunder(): Promise<Kunde[]> {
      const { data, status } = await fetchGet("bruger/GetAvailableMunicipalitiesForUser");
      return data as Kunde[];
    }
  }
});
